<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{
                            this.member.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }} {{ $t("module.memberLoyalty") }}</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                        <input
                            type="hidden"
                            class="form-control"
                            v-model.number="member.id"
                        />
                        <fieldset>
                            <legend>{{ $t('member.base_information') }}</legend>
                            <div class="row">

                                <!-- <div class="col-md-6"> -->
                                    <!-- <div class="row"> -->
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12 form-group">
                                                    <label for="userId">User ID* </label>
                                                    <input type="text" class="form-control" v-model="member.user_id"  :disabled="modeView"/>
                                                    <!-- <select-dropdown
                                                        :render-select="userRenderSelect"
                                                        validate-rule="required"
                                                        :isDisabled="isDisabledSelect"
                                                        valueKey="userId"
                                                        repo-name="userRepository"
                                                        @update:changedValue="onUserSelected"
                                                        ref="userSelectVue"
                                                        :id-select-dropdown="'user_id_' + member.id"
                                                        :selected-dropdown="parseInt(member.user_id)"
                                                        ></select-dropdown> -->
                                                    <div v-show="errors.first('user_id')" class="custom-invalid-feedback" >{{ errors.first('user_id') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div> -->

                                    <!-- <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12 form-group">
                                                    <label for="inputFullname">{{ $t('common.fullname') }}* :</label>
                                                    <input type="text" v-model="pasienModel.patientName"
                                                            disabled="disabled" class="form-control"
                                                            :placeholder="$t('common.fullname')">
                                                </div>
                                                <div class="col-md-12 form-group">
                                                    <label for="inputbirthdate">{{ $t('common.birthdate') }}:</label>
                                                    <input type="text" v-model="pasienModel.dateOfBirth" disabled="disabled"
                                                        class="form-control" id="date-of-birth" :placeholder="$t('common.birthdate')">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                <!-- </div> -->

                                <!-- <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="address">{{ $t('common.address') }}:</label>
                                            <textarea disabled="disabled" v-model="pasienModel.addresses" rows="6" class="form-control"></textarea>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-6 form-group">
                                    <label for="gender">{{ $t('common.gender') }}:</label>
                                    <input type="text" v-validate="'required'" v-model="pasienModel.jenisKelamin"
                                    disabled="disabled" class="form-control" :placeholder="$t('common.gender')">
                                </div> -->
                                <!-- <div class="col-md-6 form-group">
                                    <label for="status">{{ $t('common.mariage_status') }}:</label>
                                    <input type="text" v-validate="'required'" v-model="pasienModel.statusPernikahan"
                                    disabled="disabled" class="form-control" id="poin"
                                    :placeholder="$t('common.mariage_status')">

                                </div> -->
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ $t('member.member_information') }}</legend>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="name_on_card">{{ $t('member.name_on_card') }}* :</label>
                                    <input type="text"
                                    v-validate="{required:true,max:100 }"
                                    v-model="member.name_on_card"
                                    :disabled="modeView" name="name_on_card" :class="{'form-control':true,'is-invalid': errors.has('name_on_card')}"
                                    :placeholder="$t('member.name_on_card')"
                                    :data-vv-as="$t('member.name_on_card')"
                                    >
                                    <div v-show="errors.first('name_on_card')" class="invalid-feedback" >{{ errors.first('name_on_card') }}</div>
                                </div>
                                <div class="col-md-6">
                                    <label for="member_number">{{ $t('member.member_number') }}:</label>
                                    <input type="text" v-validate="'required'" name="medical-no" v-model="member.member_no"
                                    disabled="disabled" class="form-control" :placeholder="$t('member.member_number')">
                                </div>
                                <div class="col-md-6">
                                    <label for="point">{{ $t('member.point') }}* :</label>
                                    <input type="number" min="0" v-validate="'required'" name="point"
                                    max="99999999" pattern="[0-9]" step="1"
                                    v-model="member.point"
                                    :disabled="modeView" :class="{'form-control':true,'is-invalid': errors.has('point') }"
                                    :placeholder="$t('member.point')"
                                    :data-vv-as="$t('member.point')">
                                    <div v-show="errors.first('point')" class="invalid-feedback" >{{ errors.first('point') }}</div>
                                </div>
                                <div class="col-md-6">
                                    <label for="loyalty">{{ $t('member.loyalty') }}* :</label>
                                    <input type="number" min="0" name="loyalty" v-validate="'required'"
                                    max="99999999" pattern="[0-9]" step="1"
                                    v-model="member.loyalty" :disabled="modeView" :class="{'form-control':true,'is-invalid':errors.has('loyalty')}"
                                    :placeholder="$t('member.loyalty')"
                                    :data-vv-as="$t('member.loyalty')">
                                    <div v-show="errors.first('loyalty')" class="invalid-feedback" >{{ errors.first('loyalty') }}</div>
                                </div>
                                <div class="col-md-6">
                                    <label for="card">{{ $t('common.card') }}* :</label>
                                    <select-dropdown
                                        validate-rule="required"
                                        :isDisabled="modeView"
                                        :render-select="loyaltyTierRenderSelect"
                                        repo-name="loyaltyTierRepository"
                                        @update:changedValue="onLoyaltyTierUpdated"
                                        ref="loyaltySelectVue"
                                        :id-select-dropdown="'loyalty_tier_id_' + member.id"
                                        :selected-dropdown="parseInt(member.loyalty_tier_id)"
                                        :has-error="errors.has('loyalty_tier_id')"
                                    ></select-dropdown>
                                    <div v-show="errors.first('loyalty_tier_id')" class="custom-invalid-feedback" >{{ errors.first('loyalty_tier_id') }}</div>
                                </div>
                                <div class="col-md-6">
                                    <label for="joined_date">{{ $t('member.joined_date') }}:</label>
                                    <input type="date" v-validate="'required'" v-model="member.joined_date" class="form-control"
                                        :disabled="modeView"
                                        :placeholder="$t('member.joined_date')">
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="hideModal()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            v-if="!modeView"
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import SelectDropdown from "./../../components/base/SelectDropdown";
import DatePicker from "./../../../components/_general/DatePicker.vue";
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import { isNullOrUndefined } from "util";
import MemberModel from "./../../model/member-model";
import PasienModel from "./../../model/pasien-model";
import { mapState, mapMutations, mapActions } from "vuex";
import BlockUI from "./../../../components/_js/BlockUI";
const blockUI = new BlockUI();
const MemberRepository = RepositoryFactory.get("memberRepository");
const UserRepository = RepositoryFactory.get("userRepository");
const loyaltyTierRepository = RepositoryFactory.get("loyaltyTierRepository");
const SweetAlert = Vue.extend(SweetAlertTemplate);
VeeValidate.setMode("passive");
export default {
    components: {
        SelectDropdown
    },
    props: {
        event: {
            type: Function
        }
    },
    data() {
        return {
            member: new MemberModel(),
            pasienModel: new PasienModel(),
            isChangeNameOnCard : false,
            timeOutSearch: null,
            isSearchFormat: false
        };
    },
    provide() {
        return { $validator: this.$validator };
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal,
        }),
        modeView(){
            return this.showModalMode == 'V';
        },
        isDisabledSelect(){
            return this.modeView;
            // return this.dataEdit.id ? true : this.modeView;
        },
        // isSearchFormat () {
        //     if (this.userId && this.member.joined_date) {
        //         if(this.timeOutSearch) clearTimeout(this.timeOutSearch)
        //         this.timeOutSearch
        //     }
        //     // console.log('<----', this.userId)
        //     // console.log('-->', this.member.joined_date)
        //     return false
        // }
    },
    watch:{
        'member.user_id': function (val) {
           if (val && this.member.joined_date) {
                this.searchMemberId(val, this.member.joined_date)
           }
        },
        'member.joined_date': function (val) {
            if (this.member.user_id && val) {
                this.searchMemberId(this.member.user_id, val)
           }
        }
    },
    methods: {
        searchMemberId (userId, createdDate) {
            if(this.timeOutSearch) clearTimeout(this.timeOutSearch)
            this.timeOutSearch = setTimeout(() => {
                 this.isSearchFormat = true
                 MemberRepository.formatNoMember(userId, createdDate)
                .then(response => {
                    this.isSearchFormat = false
                    if(response.data.status){
                        this.pasienModel.medicalNoFormat = response.data.message;
                        this.member.member_no = response.data.message;
                        
                    }
                })
                .catch(() => this.isSearchFormat = false);
            }, 1000)
        },
        onLoyaltyTierUpdated(val){
            try{
                this.member = Object.assign(this.member,{
                    loyalty_tier_id:val.id
                });
            }
            catch(e){}
            $('#loyalty_tier_id_' + this.member.id).parent().removeClass('is-invalid');
            this.errors.remove('loyalty_tier_id');
        },

        onUserSelected(val){
            if(val && val.userId != null){
                UserRepository.getUserById(val.userId)
                .then(response => {
                    this.showPatientInfo(response.data,val);
                });
                MemberRepository.formatNoMember(val.userId, val.createdDate)
                .then(response => {
                    if(response.data.status){
                        this.pasienModel.medicalNoFormat = response.data.message;
                        this.member.member_no = response.data.message;
                    }
                });
            }
        },
        showPatientInfo(val, user){
            try{
                if(val){
                    let patientAddress = '';
                    let jk = '';
                    let nikah = '';
                    let allPatientAddress = [];

                    if(val.addresses && val.addresses[0]){
                        let countAddress = val.addresses.length;
                        for(let a in val.addresses ){
                            patientAddress = [];
                            patientAddress.push(val.addresses[a].line1)
                            if('string'== typeof(val.addresses[a].line2) && val.addresses[a].line2.length>2){
                                patientAddress.push(val.addresses[a].line2)
                            }
                            patientAddress.push(val.addresses[a].city)
                            patientAddress.push(val.addresses[a].district)
                            patientAddress.push(val.addresses[a].country)
                            patientAddress.push(val.addresses[a].province)
                            patientAddress.push(val.addresses[a].zipCode)
                            let prefixAddr = countAddress ==  1 ? '':`Alamat ${parseInt(a)+1}: `;
                            allPatientAddress.push(prefixAddr+patientAddress.join(', ')+"\n\n")
                        }
                    }
                    if(val.jenisKelamin){
                        jk = val.jenisKelamin.name;
                    }
                    if(val.statusPernikahan){
                        nikah = val.statusPernikahan.name;
                    }
                    let dateOfBirth = moment(val.dateOfBirth)
                    dateOfBirth = dateOfBirth.isValid() ? dateOfBirth.format('YYYY-MM-DD'): null;

                    let newDataPatient = {
                        patientName: val.patientName || '',
                        addresses: allPatientAddress.join(""),
                        dateOfBirth: dateOfBirth,
                        jenisKelamin: jk,
                        medicalNo: val.medicalNo,
                        statusPernikahan: nikah
                    };
                    this.pasienModel = Object.assign({}, newDataPatient);



                    let joinedDate = moment(user.createdDate);
                    joinedDate = joinedDate.isValid() ? joinedDate.format('YYYY-MM-DD HH:MM:SS') : null;
                    this.member = Object.assign(this.member,{
                        user_id: user.userId,
                        // name_on_card: (this.member.name_on_card != '') ? this.member.name_on_card : (val.patientName || ''),
                        // name_on_card: val.patientName || '',
                        joined_date:joinedDate,
                    });

                    // if(this.dataEdit && this.dataEdit.id && !this.isChangeNameOnCard){
                    //     this.isChangeNameOnCard = true;
                    // }else{
                    //     this.isChangeNameOnCard = true;
                    //     // this.member.name_on_card = val.patientName || '';
                    // }
                    if((this.member.name_on_card == null || this.member.name_on_card == '') || this.isChangeNameOnCard){
                        this.member.name_on_card = val.patientName || '';
                        this.isChangeNameOnCard = true;
                    }
                }
            }catch(e){
                // this.errors.add({field: 'patient_id', msg: this.$t('sentences.failed_to_get_member_data_please_contact_'), rule: "required"});
                this.errors.add({field: 'user_id', msg: this.$t('sentences.failed_to_get_member_data_please_contact_'), rule: "required"});
                // console.log(e);
            }

            // $('#patient_id_' + this.member.id).parent().removeClass('is-invalid');
            // this.errors.remove('patient_id');
            $('#user_id_' + this.member.id).parent().removeClass('is-invalid');
            this.errors.remove('user_id');
        },

        userRenderSelect(val){
            return `${val.userId}`;
        },

        loyaltyTierRenderSelect(val){
            return (val.name) ? val.name : '';
        },
        ...mapActions({
            createNew: "Crud/createNew",
            updateById: "Crud/updateById"
        }),
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),
        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
        },

        updateData: function() {
            if (this.dataEdit) {
                this.member = Object.assign({}, this.dataEdit);
                this.member.joined_date = moment(this.member.joined_date).format('YYYY-MM-DD')
            }
        },
        submitData: function() {
            let valid = true;
            this.errors.clear();
            $('#user_id_' + this.member.id).parent().removeClass('is-invalid');
            $('#loyalty_tier_id_' + this.member.id).parent().removeClass('is-invalid');
            if(this.member.user_id == null){
                this.errors.add({field: 'user_id', msg: this.$t('sentences.user_id_is_required'), rule: "required"});
                $('#user_id_' + this.member.id).parent().addClass('is-invalid');
                valid = false;
            }
            if(this.member.loyalty_tier_id == null){
                this.errors.add({field: 'loyalty_tier_id', msg: this.$t('sentences.card_is_required'), rule: "required"});
                $('#loyalty_tier_id_' + this.member.id).parent().addClass('is-invalid');
                valid = false;
            }
            this.$validator.validateAll().then(result => {
                if (result) {
                    var vx = this;
                    if(!valid){
                        return;
                    }
                    if (this.member.id) {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.member, (v, k) => {
                                    data[k] = v;
                                });
                                vx.update(data, vx.member.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                // const data = new FormData();
                                const data = {};
                                _.forEach(vx.member, (v, k) => {
                                    // data.append(k, v);
                                    data[k] = v;
                                });
                                vx.create(data);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }
                    return;
                }else{

                }
            });
        }, //submitdata
        async create(payload) {
            var vx = this;
            await MemberRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Member berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Member gagal ditambahkan."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                    });
            },
            async update(payload,tierId) {
                var vx = this;

                await MemberRepository.update(payload,tierId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Member berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Member gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        }
    }, //methods
    created(){

    },
    mounted() {
        window.thisFormModal = this;
        $("#" + this.modalName).modal();
        this.updateData();
    }
};
</script>
