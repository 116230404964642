export default function MemberModel() {
    return {
        id:null,
        name_on_card:null,
        member_no:null,
        point:null,
        loyalty:null,
        joined_date:null,
        user_id:null,
        loyalty_tier_id:null
      };
}
